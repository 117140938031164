import React, {useState} from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet, ScrollView, Dimensions} from "react-native";
import logo from '../assets/logoGreen.png'; 
import back from '../assets/back.png'; 

const styles = StyleSheet.create({
    background: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F9F4F1',
        flexWrap: 'wrap',
        maxHeight:  Dimensions.get('window').height,
    },
    title: {
        color: '#254D4F',
        fontSize: 34,
        fontWeight: '600',
        marginBottom: 10,
    },
    menu: {
        color: '#254D4F',
        fontSize: 16,
        fontWeight: '400',
        alignSelf: 'center',
        textAlign: 'center',
    },
    menuW: {
        color: 'white',
        fontSize: 16,
        fontWeight: '400',
        alignSelf: 'center',
        textAlign: 'center',
    },
    menuClicked: {
        backgroundColor: '#254D4F',
        borderRadius: '18px',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    menuUnclicked: {
        backgroundColor: 'white',
        borderRadius: '18px',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    sub: {
        color: '#254D4F',
        fontSize: 20,
        fontWeight: '300',
        paddingBottom: 20,
        lineHeight: 30,
    },
    botSub: {
        color: '#254D4F',
        fontSize: 20,
        fontWeight: '300',
    },
    header: {
        width: '90%',
        height: '20%',
        maxHeight: '20%',
        justifyContent: 'center',
    },
    center: {
        width: '90%',
        height: '60%',
        maxHeight: '60%',
    },
    bottom: {
        width: '100%',
        height: '15%',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'none',
    },
    boxBot: {
        width: '65%',
        height: '70%',
        justifyContent: 'space-around',
    },
    btn: {
        backgroundColor: '#254d4e',
        padding: 7,
        width: '60%',
        alignSelf: 'center',
        borderRadius: '20px',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    btnTxt: {
        color: 'white',
        fontWeight: '500',
    },
    logo: {
        width: '60%',
        height: 20,
        alignSelf: 'center',
    },
    row: {
        flexDirection: 'row',
        backgroundColor: 'white',
        borderRadius: '18px',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    back: {
        width: 30,
        height: 20,
    },
     backCont: {
        width: '10%',
        marginBottom: 10,
    },
});

export default function Privacy(props) {
    console.log(props.route);
    const [cookie, setCookie] = useState(props.route.params.cookie);

    return (
    <View  style={styles.background}>
        <View style={styles.header}>
            <TouchableOpacity style={styles.backCont} onPress={() => {props.navigation.goBack()}}>
                <Image style={styles.back} source={back} resizeMode="contain" />
            </TouchableOpacity>
            <Text style={styles.title}>Privacy policy</Text>
            <View style={styles.row}>
                <TouchableOpacity style={cookie ? styles.menuClicked : styles.menuUnclicked} onPress={() => {setCookie(true)}}>
                    <Text style={cookie ? styles.menuW : styles.menu}>Legal notice</Text>
                </TouchableOpacity>
                <TouchableOpacity style={!cookie ? styles.menuClicked : styles.menuUnclicked} onPress={() => {setCookie(false)}}>
                    <Text style={!cookie ? styles.menuW : styles.menu}>Terms & conditions</Text>
                </TouchableOpacity>
            </View>
        </View>
        {
            cookie ?
            <ScrollView style={styles.center}>
                {/* <ScrollView> */}
                    <Text style={styles.sub}>The provider of this website within the legal meaning of the term is the registered company of TIPITIZI SARL with a share capital of 1.010 €, registered in the Paris Trade and Companies Register (RCS) under the N° SIREN 848 506 861.</Text>
                    <Text  style={styles.sub}>Address:</Text>
                    <Text  style={styles.sub}>
                        TIPITIZI <br/>
                        Bureau 562 <br/>
                        59 rue de Ponthieu <br/>
                        75008 Paris <br/>
                        France<br/>
                    </Text>
                    <Text  style={styles.sub}>
                        Website: www.tipitizi.com<br/>
                        E-Mail: help@tipitizi.com<br/>
                    </Text>
                    <Text  style={styles.sub}>
                        Tipitizi is legally represented by Nicolas Baudy.
                    </Text>
                    <Text  style={styles.sub}>
                        Value Added Tax Identification Number : FR43902519891
                    </Text>
                {/* </ScrollView> */}
            </ScrollView> :
            <ScrollView style={styles.center}>
                {/* <ScrollView> */}
                {/* <ScrollView> */}
                    <Text style={styles.sub}>The web application "https://tipitizi.com" (hereinafter the "Web application") allows you to tip online, directly to the employee of a hotel or restaurant of your choice (hereinafter after the "Tip"), under the conditions and according to the methods defined in these General Conditions of Sale (hereinafter the "GTC") concluded between the customer (hereinafter the "Customer") and TIPITIZI (hereinafter after the "Supplier").</Text>
                    <Text style={styles.sub}>These T&Cs apply to the exclusion of all other conditions.</Text>
                    <Text style={styles.sub}>ARTICLE 1: PURPOSE AND TERMS OF THE ORDER</Text>
                    <Text style={styles.sub}>
                        1.1 Choice of establishment<br/>
                        The Customer begins by choosing the establishment (hotel or restaurant) in which the employee to whom the Customer wishes to tip works.<br/>
                        1.2 Employee Selection<br/>
                        Once the establishment has been selected, the Customer can search for an employee by scrolling through the different employees working in the establishment.<br/>
                        1.3 Tip amount selection<br/>
                        Once the employee has been selected, the Customer chooses the amount of the Tip he wishes to pay to the employee. The Customer declares to have read these T&Cs before paying the Tip.<br/>
                    </Text>
                    <Text style={styles.sub}>ARTICLE 2: CUSTOMER INFORMATION</Text>
                    <Text style={styles.sub}>
                        2.1- It is up to the Customer to choose the amount he wants to pay the employee and to verify it before paying the tip.<br/>
                        2.2- The Customer is solely responsible for the information provided when paying the tip.<br/>
                    </Text>
                    <Text style={styles.sub}> ARTICLE 3: TERMS OF PAYMENT</Text>
                    <Text style={styles.sub}>
                        4.1- After choosing the payment amount, the Customer validates the tip and proceeds to payment. The Tip will only be final after payment of the corresponding amount.<br/>
                        4.2- Payment is made through a payment provider (STRIPE), using the bank details provided by the Customer. Said bank details are neither transmitted nor, a fortiori, stored by TIPITIZI.<br/>
                        4.3- Once the payment has been validated, the Customer will receive a confirmation as well as a summary of his payment by e-mail to the e-mail address he has provided.<br/>
                        4.4- The payment will be effective once it has been validated by the Customer.<br/>
                    </Text>
                    <Text style={styles.sub}>ARTICLE 5: WITHDRAWAL</Text>
                    <Text style={styles.sub}>The Customer has no right of withdrawal. By validating his payment, the Customer acknowledges that he cannot claim a refund of the tip paid.</Text>
                    <Text style={styles.sub}>ARTICLE 6: RESPONSIBILITIES</Text>
                    <Text style={styles.sub}>
                        The Supplier cannot be held liable for damages of any kind, whether material or immaterial, which may result from improper use of the tip payment procedure.<br/>
                        The Supplier cannot be held liable for any risk inherent in the use of the Internet network such as intrusion, virus, service interruption or other involuntary problems.<br/>
                    </Text>
                    <Text style={styles.sub}>ARTICLE 7: INTELLECTUAL PROPERTY</Text>
                    <Text style={styles.sub}>
                        8.1- The Web Application is protected by intellectual and/or industrial property rights.<br/>
                        8.2- The Site as a whole as well as each element (in particular texts, brands, logos, images and drawings) of the Site are the exclusive property of Tipitizi SARL.<br/>
                        8.3- Any total or partial reproduction on paper or computer, exploitation, redistribution or use for any reason whatsoever of the elements of the Web Application is strictly prohibited. Any simple or hypertext link is strictly prohibited without the express written consent of Tipitizi SARL.<br/>
                    </Text>
                    <Text style={styles.sub}>ARTICLE 8: APPLICABLE LAW - DISPUTE</Text>
                    <Text style={styles.sub}>These T&Cs are governed by French law.</Text>
                {/* </ScrollView> */}
            </ScrollView>
        }
        <View style={styles.bottom}>
            <View style={styles.boxBot}>
                <TouchableOpacity style={styles.btn}>
                    <Text onPress={() => props.navigation.goBack()} style={styles.btnTxt}>tip without cash</Text>
                </TouchableOpacity>
                <Image style={styles.logo} source={logo} resizeMode="contain" />
            </View>
        </View>
    </View>
  );

}
