import React from 'react';
import {TouchableOpacity, StyleSheet, Image} from 'react-native';
import back from '../assets/back.png'; 

export const Back = ({navigation, goToHome}) => {
  return (
    <TouchableOpacity style={styles.backCont} onPress={() => {
      if (goToHome) {
        navigation.replace('Home')
      } else {
        navigation.goBack();
      }
      }}>
        <Image style={styles.back} source={back} resizeMode="contain" />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
    back: {
        width: 30,
        height: 10,
    },
        backCont: {
        position: 'absolute',
        top: 5,
        left: 1,
        padding: 10,
        zIndex: 5,
    },
});
