import React, {useState, useEffect} from "react";
import { View, Text, TextInput, Image, TouchableOpacity, StyleSheet} from "react-native";
import tmp from '../assets/tmp.png'; 
import {Bottom} from '../component/bottom';
import {Back} from '../component/back';
import logo from '../assets/logoGreen.png'; 
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../component/CheckoutForm.js";
const styles = StyleSheet.create({
    background: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F9F4F1',
        alignItems: 'center',
    },
    header: {
        height: '12%',
        width: '82%',
        paddingTop: 40,
    },
    center: {
        backgroundColor: 'white',
        width: '90%',
        borderRadius: '40px',
        alignItems: 'center',
        padding: 30,
        overflow: 'scroll'
    },
    step: {
        color: '#254d4e',
        fontSize: 14,
        fontWeight: '300',
        marginBottom: 5,
    },
    progress: {
        backgroundColor: 'white',
        borderRadius: '15px',
        width: '100%',
        height: 6,
        },
    bar: {
        backgroundColor: '#254d4e',
        borderRadius: '15px',
        width: '100%',
        height: 6,
    },
    title: {
        color : '#254d4e',
        fontSize: 26,
        fontWeight: '500',
        textAlign: 'center',
    },
    logo: {
        width: '60%',
        height: 20,
        alignSelf: 'center',
    },
    bot: {
        justifyContent: 'flex-end',
        height: '27%',
    },
    textW: {
        color: 'white',
    },
    btn: {
        backgroundColor: '#254d4e',
        padding: 7,
        borderRadius: 18,
        marginBottom: 12,
    },
});

const stripePromise = loadStripe("pk_test_51Hjmy2AQRYPyBHEKXdfp09HjfACfHvjA7XUCbxwbz2E0YeFBb32rzAS8UMCF7RFMx2sg5Y8yDGnKpa2SUrDwhTi9004K7Rokh7");

export default function Third(props) {
    const [clientSecret, setClientSecret] = useState(null);
    console.log(props.route);
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch("https://api.tipitizi.com/api/create_payment", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount: props.route.params.amount * 100, users: props.route.params.users }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setClientSecret(data.client_secret)
        });
      }, []);
    const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };
      console.log(options)
    return (
    <View  style={styles.background}>
        <View style={styles.header}>
            <Text style={styles.step}>Step 3/3</Text>
            <View style={styles.progress}>
                <View style={styles.bar} />
            </View>
        </View>
        <View style={styles.center}>
            <Text style={styles.title}>Payment options</Text>
            <View>
                {clientSecret != null &&
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm />
                    </Elements>
                }
            </View>
        </View>
        <View style={styles.bot}>
            {/* <TouchableOpacity style={styles.btn} onPress={() => {props.navigation.navigate('Thanks')}}>
                <Text  style={styles.textW}>Continue</Text>
            </TouchableOpacity> */}
            <Image style={styles.logo} source={logo} resizeMode="contain" />
        </View>
        <Back navigation={props.navigation}/>
        <Bottom navigation={props.navigation}/>
    </View>
  );

}
