import React, {useState} from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet} from "react-native";
import logo from '../assets/logoGreen.png'; 
import {Bottom} from '../component/bottom';
import {Back} from '../component/back';
import { CurrentRenderContext } from "@react-navigation/native";

const styles = StyleSheet.create({
  background: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F9F4F1',
  },
  title: {
    color: '#EBD4B3',
    fontSize: 34,
    fontWeight: '500',
    alignSelf: 'center',
    marginTop: 10,
  },
  sub: {
    color: 'white',
    fontSize: 21,
    fontWeight: '300',
    alignSelf: 'center',
    marginTop: 10,
    textAlign: 'center',
  },
  botSub: {
    color: '#254D4F',
    fontSize: 21,
    fontWeight: '300',
    alignSelf: 'center',
    textAlign: 'center',
  },
  header: {
    width: '100%',
    height: '65%',
    backgroundColor: '#254D4F',
    borderBottomLeftRadius: '200px',
    borderBottomRightRadius: '200px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textBox: {
    justifyContent: 'space-between',
    width: '65%',
    height: '30%'
  },
  boxBot: {
    width: '65%',
    height: '70%',
    justifyContent: 'space-around',
  },
  btn: {
    backgroundColor: '#254d4e',
    padding: 7,
    width: '40%',
    alignSelf: 'center',
    borderRadius: '20px',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: 15,
  },
  btnTxt: {
    color: 'white',
    fontWeight: '500',
  },
  logo: {
    width: '60%',
    height: 20,
    alignSelf: 'center',
  },
  bottom: {
    width: '100%',
    height: '35%',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default function ThanksSignup(props) {

    return (
    <View  style={styles.background}>
        <View style={styles.header}>
            <View style={styles.textBox}>
                <Text style={styles.title}>Thank you !</Text>
                <Text style={styles.sub}>Yout account has been created successfully</Text>
            </View>
        </View>
        <View style={styles.bottom}>
            <View style={styles.boxBot}>
                <Text style={styles.botSub}>If you want to learn more about tipitizi: Hello@tipitizi.com</Text>
                <Image style={styles.logo} source={logo} resizeMode="contain" />
            </View>
        </View>
        <Back navigation={props.navigation}/>
        <Bottom navigation={props.navigation}/>
    </View>
  );

}
