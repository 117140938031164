import React, {useState, useEffect} from "react";
import { View, Text, TextInput, Image, TouchableOpacity, StyleSheet} from "react-native";
import Entypo from '@expo/vector-icons/Entypo';
import logo from '../assets/logoGreen.png'; 
import forward from '../assets/forward.png'; 
import tmp from '../assets/tmp.png'; 
import {Bottom} from '../component/bottom';
import {Back} from '../component/back';

const styles = StyleSheet.create({
  background: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  sub: {
    color: '#254D4E',
    fontSize: 17,
    fontWeight: '200',
    alignSelf: 'center',
    marginTop: 10,
  },
  forward: {
    width: 30,
    height: 10,
  },
  header: {
    width: '100%',
    height: '10%',
    alignItems: 'center',
    paddingTop: 20,
  },
  logo: {
    width: 200,
    height: 40,
  },
  center: {
    width: '100%',
    height: '80%',
    backgroundColor: '#F9F4F1',
    borderTopLeftRadius: '200px',
    borderTopRightRadius: '200px',
    position: 'absolute',
    bottom: 0,
    justifyContent: 'center',
  },
  img: {
    width: '80%',
    height: 240,
    borderRadius: '35px',
    alignSelf: 'center',
    marginBottom: 10,
    marginTop: -50,
  },
  contentTitle: {
    width: '80%',
    color: '#254D4E',
    fontSize: 26,
    alignSelf: 'center',
    fontWeight: '500',
  },
  contentSub: {
    width: '80%',
    color: '#254D4E',
    fontSize: 14,
    alignSelf: 'center',
    fontWeight: '300',
    marginTop: 5,
  },
  btn: {
      backgroundColor: '#254d4e',
      padding: 7,
      flexDirection: 'row',
      width: '60%',
      alignSelf: 'center',
      borderRadius: '20px',
      position: 'absolute',
      bottom: 70,
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 20,
  },
  btnTxt: {
      color: 'white',
      fontWeight: '500',
      fontSize: 24,
  }
});

export default function Detail(props) {
  const [result, setResult] = useState(null);

  useEffect(() => {
    fetch('https://api.tipitizi.com/api/organizations/' + props.route.params.id + '?populate=cover&populate=services.employees&populate=services.icon&populate=services.employees.profile')
    .then((response) => response.json())
    .then((res) => {
      if (res && res.data)
        setResult(res.data)

    })
    .catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <View  style={styles.background}>
        <View style={styles.header}>
            <Image style={styles.logo} source={logo} resizeMode="contain" />
            <Text style={styles.sub}>Tip anyone. Anywhere. Anytime</Text>
        </View>
        {
          result ?
          <View style={styles.center}>
            <Image style={styles.img} source={result.attributes.cover.data.attributes.url} resizeMode="cover" />
            <Text style={styles.contentTitle}>{result.attributes.name}</Text>
            <Text style={styles.contentSub}>{result.attributes.location}</Text>
            <TouchableOpacity style={styles.btn} onPress={() => {
                props.navigation.navigate('First', {
                  organis: result,
                })
            }}>
              <Text style={styles.btnTxt}>start tipping</Text>
              <Image style={styles.forward} source={forward} resizeMode="contain" />
            </TouchableOpacity>
          </View> : null
        }
        <Back navigation={props.navigation} goToHome/>
        <Bottom navigation={props.navigation}/>
    </View>
  );

}
