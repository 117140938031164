import React, {useState, useEffect} from "react";
import { View, Text, TextInput, Image, FlatList, StyleSheet, TouchableOpacity} from "react-native";
import logo from '../assets/logoGreen.png'; 
import tmp from '../assets/tmp.png'; 
import {Bottom} from '../component/bottom';

const styles = StyleSheet.create({
  background: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F9F4F1',
    height: '500px'
  },
  title: {
    color: '#254D4E',
    fontSize: 30,
    fontWeight: '500',
    alignSelf: 'center',
  },
  contentTitle: {
    color: '#254D4E',
    fontSize: 26,
    fontWeight: '500',
    marginTop: 10,
  },
  contentSub: {
    color: '#254D4E',
    fontSize: 14,
    fontWeight: '300',
  },
  logo: {
    width: '20%',
    height: '7%',
    marginTop: 20,
  },
  center: {
    backgroundColor: 'white',
    width: '90%',
    height: '70vh',
    borderRadius: '40px',
    paddingTop: 20,
    marginTop: 40,
    maxWidth: "450px"
  },
  input: {
    backgroundColor: 'white',
    border: '1px solid #254D4F',
    borderRadius: '16.5px',
    alignSelf: 'center',
    width: '85%',
    padding: 5,
    marginTop: 20,
    color: '#254D4F',
    paddingLeft: 13,
    paddingRight: 13,
    marginBottom: 20,
  },
  content: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10,
      marginTop: 10,
      width: '85%',
      alignSelf: 'center',
      marginBottom: 20,
  },
  cover: {
      width: '100%',
      height: 180,
      alignSelf: 'center',
      borderRadius: '36px',
  },
});

export default function Home(props) {
    const [searchTxt, setSearchText] = useState('');
    const [result, setResult] = useState([]);
    // const results = [{cover: tmp, name: 'Hard Rock Hotel', location: 'Punta Cana, Dominicain Republic'},
    //                 {cover: tmp, name: 'Hard Rock Hotel', location: 'Punta Cana, Dominicain Republic'},];
    // const result = [{cover: tmp, name: 'Hard Rock Hotel', location: 'Punta Cana, Dominicain Republic'},
    //                 {cover: tmp, name: 'Hard Rock Hotel', location: 'Punta Cana, Dominicain Republic'},
    //                 {cover: tmp, name: 'Hard Rock Hotel', location: 'Punta Cana, Dominicain Republic'},
    //                 {cover: tmp, name: 'Hard Rock Hotel', location: 'Punta Cana, Dominicain Republic'},];

    useEffect(() => {
      fetch('https://api.tipitizi.com/api/organizations?populate=cover&populate=services.employees')
      .then((response) => response.json())
      .then((res) => {
        if (res && res.data)
          setResult(res.data)
      })
      .catch((error) => {
        console.error(error);
      });
    }, []);

    function filterOrganis(val) {
      fetch('https://api.tipitizi.com/api/organizations?filters[name][$contains]=' + val + '&populate=cover&populate=services.employees')
      .then((response) => response.json())
      .then((res) => {
        if (res && res.data)
          setResult(res.data)
      })
      .catch((error) => {
        console.error(error);
      });
    }

    return (
    <View
        style={styles.background}>
        <Image style={styles.logo} source={logo} resizeMode="contain" />
        <View style={styles.center}>
            <Text style={styles.title}>Find your hotel</Text>
            <TextInput
                style={styles.input}
                placeholder={'Type something'}
                onChangeText={(val) => {setSearchText(val); filterOrganis(val)}}
                value={searchTxt}
            />
            <FlatList
                data={result}
                renderItem={({item}) => {
                    return (
                        <TouchableOpacity style={styles.content} onPress={() => {
                            props.navigation.navigate('Detail', {
                              id: item.id,
                            });
                          }}>
                            <Image style={styles.cover} source={item.attributes.cover.data.attributes.url} resizeMode="cover" />
                            <Text style={styles.contentTitle}>{item.attributes.name}</Text>
                            <Text style={styles.contentSub}>{item.attributes.location}</Text>
                        </TouchableOpacity>
                    );
                }}
                keyExtractor={item => item.id}
            /> 
        </View>
        <Bottom navigation={props.navigation}/>
    </View>
  );

}
