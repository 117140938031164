import React, {useState, useEffect} from "react";
import { View, Text, TextInput, Image, TouchableOpacity, StyleSheet, Dimensions} from "react-native";
import user from '../assets/user.png'; 
import {Bottom} from '../component/bottom';
import {Back} from '../component/back';
import logo from '../assets/logoGreen.png'; 
import ben from '../assets/ben.png'; 
import steph from '../assets/steph.png'; 
import abstra from '../assets/abstra.png'; 
import back from '../assets/back.png'; 
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

const styles = StyleSheet.create({
    background: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F9F4F1',
        alignItems: 'center',
        maxHeight: Dimensions.get('window').height,
    },
    header: {
        height: '12%',
        width: '82%',
        paddingTop: 40,
    },
    center: {
        backgroundColor: 'white',
        height: '35%',
        width: '90%',
        borderRadius: '40px',
        alignItems: 'center',
        padding: 20,
        justifyContent: 'space-between',
    },
    payAmount: {
        backgroundColor: 'white',
        height: '31%',
        width: '90%',
        borderRadius: '40px',
        alignItems: 'center',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20,
        marginBottom: 20,
        justifyContent: 'space-between'
    },
    step: {
        color: '#254d4e',
        fontSize: 14,
        fontWeight: '300',
        marginBottom: 5,
    },
    progress: {
        backgroundColor: 'white',
        borderRadius: '15px',
        width: '100%',
        height: 6,
        },
    bar: {
        backgroundColor: '#254d4e',
        borderRadius: '15px',
        width: '60%',
        height: 6,
    },
    title: {
        color : '#254d4e',
        fontSize: RFValue(26),
        fontWeight: '700',
        textAlign: 'center',
    },
    sub: {
        color : '#254d4e',
        fontSize: RFValue(22),
        fontWeight: '500',
        textAlign: 'center',
    },
    amount: {
        color : '#254d4e',
        fontSize: 24,
        fontWeight: '600',
        textAlign: 'center',
    },
    selectedTxt: {
        color : '#F9F4F1',
        fontSize: 24,
        fontWeight: '600',
        textAlign: 'center',
    },
    logo: {
        width: '60%',
        height: 20,
        marginLeft: 'auto',
        marginRight: 'auto'
        // alignSelf: 'center',
    },
    bot: {
        height: '10%',
        width: '90%',
        justifyContent: 'space-between',
    },
    textW: {
        color: 'white',
        fontFamily: '600',
        fontSize: 22
    },
    btn: {
        backgroundColor: '#254d4e',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '60%',
        width: '50%',
        borderRadius: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    row: {
        flexDirection: 'row',
        marginTop: 10,
        width: '100%',
        flexWrap: 'wrap',
    },
    rowAll: {
        flexDirection: 'row',
        height: '30%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    rowImg: {
        flexDirection: 'row',
        height: '70%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    badge: {
        padding: 2,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 20,
        borderColor: '#254d4e',
        borderWidth: 2,
        marginLeft: 5,
        marginBottom: 5,
    },
    selected: {
        padding: 2,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 20,
        borderColor: '#254d4e',
        backgroundColor: '#254d4e',
        borderWidth: 2,
        marginLeft: 5,
        marginBottom: 5,
    },
    input: {
        backgroundColor: 'white',
        border: '1px solid #254D4F',
        borderRadius: '16.5px',
        alignSelf: 'center',
        width: '100%',
        padding: 5,
        marginTop: 20,
        color: '#254D4F',
        paddingLeft: 13,
        paddingRight: 13,
        marginBottom: 20,
      },
    left:  {
        transform: 'rotate(-30deg)',
        width: 25,
        height: 25,
    },
    right:  {
        transform: 'rotate(210deg)',
        width: 25,
        height: 25,
    },
    smallRound: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        backgroundColor: '#F8F4F2'
    },
    smallRoundAll: {
        width: 30,
        height: 30,
    },
    smallRoundO: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        opacity: 0,
    },
    bigRound: {
        width: 110,
        height: 110,
        borderRadius: '50%',
        backgroundColor: '#F8F4F2'
    },
    bigRoundAll: {
        width: 60,
        height: 60,
        margin: 10,
    },
    middle: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-end'
    },
    name: {
        color : '#254d4e',
        fontSize: 20,
    },
    circleBg: {
        backgroundColor: '#F9F4F1',
        padding: 10,
        borderRadius: '50%',
    },
    circleBgO: {
        backgroundColor: '#F9F4F1',
        padding: 10,
        borderRadius: '50%',
        opacity: 0,
    },
});

const list = [{img: ben, name: 'Ben'}, {img: steph, name: 'Steph'}, {img: abstra, name: 'Abstra'}];


export default function Second(props) {
    const [amount, setAmount] = useState('');
    const [value, setValue] = useState('');
    const data = useState(props.route.params.service); //Employees or services
    const [a, setA] = useState(0);
    const [b, setB] = useState(0);
    const [c, setC] = useState(0);

    function goRight() {
        if (data[0].length <= 1) return;
        else if (data[0].length <= 2) {
            var x = a;
            setB(x);
            setA(b);
        }
        else {
            //Set first IMG index A
            var y = a + 1;
            if (y < data[0].length)
                setA(y);
            else {
                setA(0);
                y = 0;
            }
            //Set second IMG index B 
            y += 1;
            if (y < data[0].length)
                setB(y);
            else {
                setB(0);
                y = 0;
            }
            //Set third IMG index C
            y += 1;
            if (y < data[0].length)
                setC(y);
            else {
                setC(0);
                y = 0;
            }
        }
    }

    function goLeft() {
        if (data[0].length <= 1) return;
        else if (data[0].length <= 2) {
            var x = a;
            setB(x);
            setA(b);
        }
        else {
            //Set first IMG index A
            var y = a - 1;
            if (y < 0)
            {
                y = data[0].length - 1;
                setA(y);
            }
            else {
                setA(y);
            }
            //Set second IMG index B 
            y = b - 1;
            if (y < 0)
            {
                y = data[0].length - 1;
                setB(y);
            }
            else {
                setB(y);
            }
            y = c - 1;
            if (y < 0)
            {
                y = data[0].length - 1;
                setC(y);
            }
            else {
                setC(y);
            }
        }
    }

    useEffect(() => {
        switch (data[0].length) {
            case 0:
                break;
            case 1:
                setB(0)
                break;
            case 2:
                setA(0)
                setB(1)
                break;
            case 3:
                setA(0)
                setB(1)
                setC(2)
                break;
            default:
                setA(0)
                setB(1)
                setC(2)
                break;
          }
    }, []);

    return (
    <View  style={styles.background}>
        <View style={styles.header}>
            <Text style={styles.step}>Step 2/3</Text>
            <View style={styles.progress}>
                <View style={styles.bar} />
            </View>
        </View>
        {
            props.route.params.all == true ?
            <View style={styles.center}>
                <Text style={styles.title}>who are you tipping ?</Text>
                {/* <Text style={styles.title}>Tip everyone in {data[0][b].attributes.name}</Text> */}
            {
                    data ?
                    <View style={styles.rowAll}>
                        <TouchableOpacity onPress={() => {goLeft()}}>
                            <Image style={styles.left} source={back} resizeMode="contain" />
                        </TouchableOpacity>
                            {
                                <View style={data[0].length >= 2 ? styles.circleBg : styles.circleBgO}>
                                    {
                                        data[0].length >= 2 ? <Image style={styles.smallRoundAll} source={data[0][a].attributes.icon.data[0].attributes.url} resizeMode="contain" />
                                        : <View style={styles.smallRoundAll}/>
                                    }
                                </View>
                            }
                            {
                                data[0].length >= 1 ?
                                <View style={styles.circleBg}>
                                    <Image style={styles.bigRoundAll} source={data[0][b].attributes.icon.data[0].attributes.url} resizeMode="contain" />
                                </View> : null
                            }
                            {
                                <View style={data[0].length >= 3 ? styles.circleBg : styles.circleBgO}>
                                    {
                                        data[0].length >= 3 ? <Image style={styles.smallRoundAll} source={data[0][c].attributes.icon.data[0].attributes.url} resizeMode="contain" />
                                        : <View style={styles.smallRoundAll}/>
                                    }
                                </View>
                            }
                            <TouchableOpacity onPress={() => {goRight()}}>
                                <Image style={styles.right} source={back} resizeMode="contain" />
                            </TouchableOpacity>
                    </View> : null
                }
                {
                    data[0].length > 0 ?
                    <Text style={styles.name}>{data[0][b].attributes.name}</Text> : null
                }
            </View> :
            <View style={styles.center}>
                <Text style={styles.title}>who are you tipping ?</Text>
                <Text style={styles.sub}>now, pick someone :</Text>
                <View style={styles.rowImg}>
                        {
                            data[0].length >= 2 ?
                            <Image style={styles.smallRound} source={data[0][a].attributes.profile.data ? data[0][a].attributes.profile.data.attributes.url : user} resizeMode="contain" /> :
                            <View style={styles.smallRoundO}/>
                        }
                        {
                            data[0].length >= 1 ?
                            <View style={styles.middle}>
                                <TouchableOpacity onPress={() => {goLeft()}}>
                                    <Image style={styles.left} source={back} resizeMode="contain" />
                                </TouchableOpacity>
                                <Image style={styles.bigRound} source={data[0][b].attributes.profile.data ? data[0][b].attributes.profile.data.attributes.url : user} resizeMode="contain" /> 
                                <TouchableOpacity onPress={() => {goRight()}}>
                                    <Image style={styles.right} source={back} resizeMode="contain" />
                                </TouchableOpacity>
                            </View> : null
                        }
                        {
                            data[0].length >= 3 ?
                            <Image style={styles.smallRound} source={data[0][c].attributes.profile.data ? data[0][c].attributes.profile.data.attributes.url : user} resizeMode="contain" /> :
                            <View style={styles.smallRoundO}/>
                        }
                </View>
                {
                    data.length > 0 && data[0].length > 0 ?
                    <Text style={styles.name}>{data[0][b].attributes.username}</Text>
                    : null
                }
            </View>
        }
        <View style={styles.payAmount}>
            <Text style={styles.title}>Select tip amount</Text>
            <View style={styles.row}>
                <TouchableOpacity style={value == 5 ? styles.selected : styles.badge} onPress={() => {setAmount('');setValue(5)}}>
                    <Text style={value == 5 ? styles.selectedTxt : styles.amount}>5 $</Text>
                </TouchableOpacity>
                <TouchableOpacity style={value == 10 ? styles.selected : styles.badge} onPress={() => {setAmount('');setValue(10)}}>
                    <Text style={value == 10 ? styles.selectedTxt : styles.amount}>10 $</Text>
                </TouchableOpacity>
                <TouchableOpacity style={value == 20 ? styles.selected : styles.badge} onPress={() => {setAmount('');setValue(20)}}>
                    <Text style={value == 20 ? styles.selectedTxt : styles.amount}>20 $</Text>
                </TouchableOpacity>
                <TouchableOpacity style={value == 50 ? styles.selected : styles.badge} onPress={() => {setAmount('');setValue(50)}}>
                    <Text style={value == 50 ? styles.selectedTxt : styles.amount}>50 $</Text>
                </TouchableOpacity>
                <TouchableOpacity style={value == 100 ? styles.selected : styles.badge} onPress={() => {setAmount('');setValue(100)}}>
                    <Text style={value == 100 ? styles.selectedTxt : styles.amount}>100 $</Text>
                </TouchableOpacity>
                <TouchableOpacity style={value == 200 ? styles.selected : styles.badge} onPress={() => {setAmount('');setValue(200)}}>
                    <Text style={value == 200 ? styles.selectedTxt : styles.amount}>200 $</Text>
                </TouchableOpacity>
            </View>
            <TextInput
                style={styles.input}
                placeholder={'Enter amount manually'}
                onChangeText={(val) => {setValue('');setAmount(val.replace(/[^0-9]/g, ''))}}
                value={amount}
            />
        </View>
        <View  style={styles.bot}>
            <TouchableOpacity disabled={!value && !amount} style={styles.btn} onPress={() => {
                console.log(data[0][b]);
                console.log(props.route.params.all);
                var users = []
                if (props.route.params.all == true) {
                    users = data[0][b].attributes.employees.data.map((employee) => employee.id)
                } else {
                    users = [data[0][b].id]
                }
                props.navigation.navigate('Third', {amount: amount || value, users: users, all: props.route.params.all})
            }}>
                <Text  style={styles.textW}>Continue</Text>
            </TouchableOpacity>
            <View>
                <Image style={styles.logo} source={logo} resizeMode="contain" />
            </View>
        </View>
        <Back navigation={props.navigation}/>
        <Bottom navigation={props.navigation}/>
    </View>
  );

}
