import React, {useState, useEffect} from "react";
import { View, Text, TextInput, Image, TouchableOpacity, StyleSheet} from "react-native";
import {Bottom} from '../component/bottom';
import {Back} from '../component/back';
import logo from '../assets/logoGreen.png'; 
import back from '../assets/back.png'; 
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

const styles = StyleSheet.create({
    background: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F9F4F1',
        alignItems: 'center',
    },
    header: {
        height: '12%',
        width: '82%',
        paddingTop: 40,
    },
    center: {
        backgroundColor: 'white',
        height: '75%',
        width: '90%',
        borderRadius: '40px',
        alignItems: 'center',
        padding: 15,
        justifyContent: 'space-between',
    },
    step: {
        color: '#254d4e',
        fontSize: 14,
        fontWeight: '300',
        marginBottom: 5,
    },
    progress: {
        backgroundColor: 'white',
        borderRadius: '15px',
        width: '100%',
        height: 6,
        },
    bar: {
        backgroundColor: '#254d4e',
        borderRadius: '15px',
        width: '30%',
        height: 6,
    },
    titleBox: {
        width: '100%',
        alignItems: 'center',
    },
    title: {
        color : '#254d4e',
        fontSize:  RFValue(26),
        fontWeight: '700',
        textAlign: 'center',
    },
    sub: {
        color : '#254d4e',
        fontSize:  RFValue(22),
        fontWeight: '500',
        width: '80%',
        textAlign: 'center',
    },
    name: {
        color : '#254d4e',
        fontSize: RFValue(20)
    },
    textW: {
        color: 'white',
        fontFamily: '600',
        fontSize: RFValue(22)
    },
    btn: {
        backgroundColor: '#254d4e',
        // padding: 12,
        height: '8%',
        width: '50%',
        borderRadius: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: '60%',
        height: 20,
        alignSelf: 'center',
    },
    bot: {
        justifyContent: 'space-around',
        width: '100%',
        height: '10%',
    },
    circle: {
        backgroundColor: '#F9F4F1',
        width: 100,
        height: 100,
        borderRadius: 50,
    },
    row: {
        flexDirection: 'row',
        height: '20%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    left:  {
        transform: 'rotate(-30deg)',
        width: 25,
        height: 25,
        marginTop: 30,
    },
    right:  {
        transform: 'rotate(210deg)',
        width: 25,
        height: 25,
        marginTop: 30,
    },
    circleBg: {
        backgroundColor: '#F9F4F1',
        padding: 10,
        borderRadius: '50%',
    },
    circleBgO: {
        backgroundColor: '#F9F4F1',
        padding: 10,
        borderRadius: '50%',
        opacity: 0,
    },
    smallRound: {
        width: 30,
        height: 30,
    },
    bigRound: {
        width: 60,
        height: 60,
        margin: 10,
    },
    rowBar: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '95%',
        // marginTop: 10,
        // marginBottom: 10,
    },
    lineTxt: {
        color : '#254d4e',
        fontSize: 16,
        width: '70%',
        textAlign: 'center',
    },
    line: {
        width: '15%',
        height: 1,
        color: '#254d4e',
        backgroundColor: '#254d4e',
    },
    cover: {
        width: '100%',
        height: 180,
        alignSelf: 'center',
        borderRadius: '36px',
    }
});

export default function First(props) {
    const data = props.route.params.organis;
    const services = data.attributes.services.data;
    const [a, setA] = useState(0);
    const [merge, setMerge] = useState(0);
    const [b, setB] = useState(0);
    const [c, setC] = useState(0);

    function goRight() {
        if (services.length <= 1) return;
        else if (services.length <= 2) {
            var x = a;
            setB(x);
            setA(b);
        }
        else {
            //Set first IMG index A
            var y = a + 1;
            if (y < services.length)
                setA(y);
            else {
                setA(0);
                y = 0;
            }
            //Set second IMG index B 
            y += 1;
            if (y < services.length)
                setB(y);
            else {
                setB(0);
                y = 0;
            }
            //Set third IMG index C
            y += 1;
            if (y < services.length)
                setC(y);
            else {
                setC(0);
                y = 0;
            }
        }
    }

    function goLeft() {
        if (services.length <= 1) return;
        else if (services.length <= 2) {
            var x = a;
            setB(x);
            setA(b);
        }
        else {
            //Set first IMG index A
            var y = a - 1;
            if (y < 0)
            {
                y = services.length - 1;
                setA(y);
            }
            else {
                setA(y);
            }
            //Set second IMG index B 
            y = b - 1;
            if (y < 0)
            {
                y = services.length - 1;
                setB(y);
            }
            else {
                setB(y);
            }
            y = c - 1;
            if (y < 0)
            {
                y = services.length - 1;
                setC(y);
            }
            else {
                setC(y);
            }
        }
    }

    useEffect(() => {
        switch (services.length) {
            case 0:
                break;
            case 1:
                setB(0)
                break;
            case 2:
                setA(0)
                setB(1)
                break;
            case 3:
                setA(0)
                setB(1)
                setC(2)
                break;
            default:
                setA(0)
                setB(1)
                setC(2)
                break;
          }
      }, []);

    return (
    <View  style={styles.background}>
        <View style={styles.header}>
            <Text style={styles.step}>Step 1/3</Text>
            <View style={styles.progress}>
                <View style={styles.bar} />
            </View>
        </View>
        <View style={styles.center}>
            <View style={styles.titleBox}>
                <Text style={styles.title}>who are you tipping ?</Text>
                <Text style={styles.sub}>first, select a team :</Text>
            </View>
            {
                services ?
                <View style={styles.row}>
                    <TouchableOpacity onPress={() => {goLeft()}}>
                        <Image style={styles.left} source={back} resizeMode="contain" />
                    </TouchableOpacity>
                        {
                            <View style={services.length >= 2 ? styles.circleBg : styles.circleBgO}>
                                {
                                    services.length >= 2 ? <Image style={styles.smallRound} source={services[a].attributes.icon.data[0].attributes.url} resizeMode="contain" />
                                    : <View style={styles.smallRound}/>
                                }
                            </View>
                        }
                        {
                            services.length >= 1 ?
                            <View style={styles.circleBg}>
                                <Image style={styles.bigRound} source={services[b].attributes.icon.data[0].attributes.url} resizeMode="contain" />
                            </View> : null
                        }
                        {
                            <View style={services.length >= 3 ? styles.circleBg : styles.circleBgO}>
                                {
                                    services.length >= 3 ? <Image style={styles.smallRound} source={services[c].attributes.icon.data[0].attributes.url} resizeMode="contain" />
                                    : <View style={styles.smallRound}/>
                                }
                            </View>
                        }
                        <TouchableOpacity onPress={() => {goRight()}}>
                            <Image style={styles.right} source={back} resizeMode="contain" />
                        </TouchableOpacity>
                </View> : null
            }
            {
                services.length > 0 ?
                <Text style={styles.name}>{services[b].attributes.name}</Text> : null
            }
            <TouchableOpacity style={styles.btn}  onPress={() => {
                var arr = [];
                for (var y = 0; y < services[b].attributes.employees.data.length; y++) {
                    arr.push(services[b].attributes.employees.data[y])
                }

                props.navigation.navigate('Second', {
                    service: arr,
                    all: false,
                    name: services.length > 0 ? services[b].attributes.name : 'unknown',
                })
            }}>
                <Text style={styles.textW}>continue</Text>
            </TouchableOpacity>
            <View style={styles.rowBar}>
                <View style={styles.line}/>
                <Text style={styles.lineTxt}>or just tip directly the team</Text>
                <View style={styles.line}/>
            </View>
            {
                data ? <Image style={styles.cover} source={data.attributes.cover.data.attributes.url} resizeMode="cover" /> : null
            }
            <TouchableOpacity style={styles.btn}  onPress={() => {
                // var arr = [];
                // for (var i = 0; i < services.length; i++) {
                //     for (var y = 0; y < services[i].attributes.employees.data.length; y++) {
                //         arr.push(services[i].attributes.employees.data[y])
                //     }
                // }
                props.navigation.navigate('Second', {
                    service: services,
                    all: true,
                    name: services.length > 0 ? services[b].attributes.name : 'unknown',
                })
            }}>
                <Text  style={styles.textW}>tip the team</Text>
                {/* in {services.length > 0 ? services[b].attributes.name : 'this service'} */}
            </TouchableOpacity>
        </View>
        <View style={styles.bot}>
            <Image style={styles.logo} source={logo} resizeMode="contain" />
        </View>
        <Back navigation={props.navigation}/>
        <Bottom navigation={props.navigation}/>
    </View>
  );

}
