import React, { useEffect, useState } from "react";
import {TextInput, StyleSheet, TouchableOpacity, Text} from "react-native";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const styles = StyleSheet.create({
  btn: {
      backgroundColor: '#254d4e',
      marginTop: 20,
      padding: 12,
      width: '50%',
      borderRadius: 30,
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto'
  },
  textW: {
    color: 'white',
    fontFamily: '600',
    fontSize: 22
},
});

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://tipitizi.com/payment_validated",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Text>Email</Text>
      <TextInput
        style={{
            paddingBottom: '10px',
            paddingTop: '10px',
            paddingLeft: '10px',
            marginBottom: '5px',
            marginTop: '5px',
            borderRadius: '5px',
            fontSize: '16px',
            width: '100%',
            transition: 'background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease',
            border:' 1px solid #E6E6E6',
            boxShadow:' 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif'
        }}
        placeholder={'example@example.com'}
        onChangeText={setEmail}
        value={email}
      />
      <Text className="p-FieldLabel Label Label--empty">Name</Text>
      <TextInput
        style={{
            paddingBottom: '10px',
            paddingTop: '10px',
            paddingLeft: '10px',
            marginBottom: '5px',
            marginTop: '5px',
            borderRadius: '5px',
            fontSize: '16px',
            width: '100%',
            transition: 'background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease',
            border:' 1px solid #E6E6E6',
            boxShadow:' 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)'
        }}
        placeholder={'John Doe'}
        onChangeText={setName}
        value={name}
      />
      <PaymentElement id="payment-element" />

      <TouchableOpacity  disabled={isLoading || !stripe || !elements} style={styles.btn} onPress={handleSubmit}>
        <Text  style={styles.textW}>{isLoading ? "processing..." : "Pay Now"}</Text>
      </TouchableOpacity>

      {/* <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button> */}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}