import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {StyleSheet, Text} from 'react-native';

export const Bottom = ({navigation}) => {
  return (
    <>
      <View style={styles.bot} >
        <TouchableOpacity onPress={() => {navigation.navigate('Privacy', {cookie: true})}}>
          <Text style={styles.txt}>Legal notice</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {navigation.navigate('Privacy', {cookie: false})}}>
        <Text style={styles.txt}>Terms & conditions</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
    bot: {
        backgroundColor: '#254d4e',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 30,
        alignItems: 'center',
        justifyContent: 'space-around',
        position: 'absolute',
        bottom: 0
    },
    txt: {
        color: 'white',
        fontWeight: '300',
        fontSize: 12,
        textDecorationLine: 'underline'
    }
});
