import React, {useState, useEffect} from "react";
import { View, Text, TextInput, Image, FlatList, StyleSheet, TouchableOpacity, ActivityIndicator} from "react-native";
import logo from '../assets/logoGreen.png'; 
import user from '../assets/user.png'; 
import {Bottom} from '../component/bottom';
import {Picker} from '@react-native-picker/picker';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';

import { useNavigation, useRoute } from '@react-navigation/native';

const styles = StyleSheet.create({
    background: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: '#F9F4F1',
    },
    title: {
        color: '#254D4E',
        fontSize: 30,
        fontWeight: '500',
        alignSelf: 'center',
        marginBottom: 20,
    },
    logo: {
        width: '20%',
        height: '7%',
        // marginTop: 20,
    },
    center: {
        backgroundColor: 'white',
        width: '90%',
        height: '60%',
        borderRadius: '40px',
        paddingTop: 20,
    },
    input: {
        backgroundColor: 'white',
        border: '1px solid #254D4F',
        borderRadius: '16.5px',
        alignSelf: 'center',
        width: '85%',
        padding: 5,
        marginTop: 10,
        color: '#254D4F',
        paddingLeft: 13,
        paddingRight: 13,
        marginBottom: 10,
    },
    inputPicker: {
        backgroundColor: 'white',
        border: '1px solid #254D4F',
        borderRadius: '16.5px',
        alignSelf: 'center',
        width: '85%',
        padding: 5,
        marginTop: 10,
        color: '#254D4F',
        paddingLeft: 13,
        paddingRight: 20,
        marginBottom: 10,
    },
    btn: {
        backgroundColor: '#254d4e',
        padding: 7,
        borderRadius: 18,
    },
    textW: {
        color: 'white',
    },
    avatar: {
        backgroundColor: '#F9F4F1',
        padding: 10,
        borderRadius: '50%',
        marginBottom: 20,
    },
    user: {
        backgroundColor: '#F9F4F1',
        borderRadius: '50%',
        marginBottom: 20,
    },
    round: {
        width: 40,
        height: 40,
        borderRadius: '50%',
    },
    biground: {
        width: 60,
        height: 60,
        borderRadius: '50%',
    },
    error: {
        color: 'red',
        alignSelf: 'center',
        marginTop: 10,
    },
    loading: {
        backgroundColor: 'rgba(160, 160, 160, .5)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100,
        justifyContent: 'center',
    }
});

export default function SignUp(props) {

    const route = useRoute();
    const navigation = useNavigation();

    const [result, setResult] = useState([])
    const [current, setCurrent] = useState('');

    const [organisation, setOrganisation] = useState('');
    const [service, setService] = useState('');

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [image, setImage] = useState(null);
    const [fullImg, setFullImg] = useState(null);

    
    

    if (route.params){ 
        if (Object.keys(route.params).length == 1){


            if (Object.keys(route.params) == "organization") {

                useEffect(() => {
                    fetch(`https://api.tipitizi.com/api/organizations/${parseInt(Object.values(route.params))}?populate[services]=*`)
                    .then((response) => response.json())
                    .then((res) => {
                        if (res.error){
                            fetch(`https://api.tipitizi.com/api/organizations?populate[services]=*`)
                            .then((response) => response.json())
                            .then((res) => {
                                if (res.data){
                                    setResult(res.data)
                                }
                            }) 
                        }
                        

                        if (res.data){
                            console.log(res.data)
                            setResult(res.data)
                            setCurrent(res.data)
                            setOrganisation(res.data.id)
                        }
                    })
                }, [])
            }
    
            
        } else {
            navigation.navigate('Splash')
        }
        
    } else {

        useEffect(() => {

            fetch(`https://api.tipitizi.com/api/organizations?populate[services]=*`)
            .then((response) => response.json())
            .then((res) => {
                if (res.data){
                    setResult(res.data)
                }
            })
        }, [])
    }

    const pickImage = async () => {
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
        base64: true
      });
  
      if (!result.cancelled) {
        setImage(result.uri);
        setFullImg(result)
      }
    };

    

    return (
    <View style={styles.background}>
        {
            loading ?
            <View style={styles.loading}>
                <ActivityIndicator size="large" color="#254d4e" />
            </View> : null
        }
        <Image style={styles.logo} source={logo} resizeMode="contain" />
        <View style={styles.center}>
            <Text style={styles.title}>Sign Up</Text>
            <View style={{alignItems: 'center', justifyContent: 'center' }}>
                <TouchableOpacity style={image ? styles.user : styles.avatar}  onPress={pickImage}>
                    <Image source={image ? { uri: image } : user} style={image ? styles.biground : styles.round}  />
                </TouchableOpacity>
            </View>
            <TextInput
                style={styles.input}
                placeholder={'name'}
                onChangeText={setName}
                value={name}
            />
            <TextInput
                style={styles.input}
                placeholder={'email'}
                keyboardType={'email-address'}
                onChangeText={setEmail}
                value={email}
            />
            <TextInput
                style={styles.input}
                secureTextEntry={true}
                placeholder={'password'}
                onChangeText={setPassword}
                value={password}
            />

            {
                result.length > 1 ?

                        <Picker
                            selectedValue={organisation}
                            style={styles.inputPicker}
                            onValueChange={(itemValue, itemIndex) => 
                                {
                                    setOrganisation(itemValue)

                                    for (var i = 0; i < result.length; i++) {
                                        if (result[i].id == itemValue)
                                            setCurrent(result[i]);
                                    }
                                }
                            }>
                                <Picker.Item label='Please select an option...' value='0' />
                                {
                                    result.map((elem) => {
                                        return (
                                            <Picker.Item label={elem.attributes.name} value={elem.id} key={elem.id} />
                                    )})
                                }
                        </Picker>

                    :
                        <Picker
                            enabled={result.length == 1 ? true : false}
                            style={styles.input}
                            selectedValue={result.length == 1 ? result.attributes.name : ""}
                            onValueChange={(itemValue, itemIndex) => setOrganisation(itemValue)}
                        >
                            <Picker.Item label={result.length != 0 ? result.attributes.name : ""} value={result.id} />
                        </Picker>
            }

                        
            {
                current && current.attributes && current.attributes.services && current.attributes.services.data &&current.attributes.services.data.length > 0 ?
                <Picker
                    selectedValue={service}
                    style={styles.inputPicker}
                    onValueChange={(itemValue, itemIndex) =>
                        setService(itemValue)
                    }>
                        <Picker.Item label='Please select an option...' value='0' />
                        {
                            current.attributes.services.data.map((elem) => {
                                return (
                                    <Picker.Item label={elem.attributes.name} value={elem.id} key={elem.id} />
                            )})
                        }
                </Picker> : null
            }

            {
                error ?
                <Text style={styles.error}>{error}</Text>
                : null
            }
        </View>
        <TouchableOpacity disabled={!name || !password || !email} style={styles.btn}  onPress={async () => {
           setError('');
           setLoading(true);
            // myHeaders.append("Content-Type", "multipart/form-data; charset=utf-8");

            var resul = await fetch(image)
            .then(res => res.blob())
            .then(async (blob) => {
                const formData = new FormData();
                const file = new File([blob], "filename.jpeg");
                formData.append('files', file)

                var res = await axios.post('https://api.tipitizi.com/api/upload', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data;'
                    }
                })
                console.log(res);
                return res
            });
               var myHeaders = new Headers();
               myHeaders.append("Content-Type", "application/json");
               var raw = JSON.stringify({
                "username": name,
                "password": password,
                "email": email,
                "organisation": organisation,
                "service": service,
                "profile": resul.data[0].id
               });
               
               var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
               };
               fetch("https://api.tipitizi.com/api/auth/local/register", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    setLoading(false);
                    if (result.user == null) {
                        setError(result.error.message);
                    }
                    else
                        window.location.href = result.user.link.url;
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error', error)
                });
            }}>
                <Text  style={styles.textW}>Signup</Text>
            </TouchableOpacity>
        <Bottom navigation={props.navigation}/>
    </View>
  );

}
