import React, {useState, useEffect, useCallback} from "react";
import { View, Text } from "react-native";
import Entypo from '@expo/vector-icons/Entypo';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import {StyleSheet, Image, TouchableOpacity} from 'react-native';
import logo from '../assets/logo.png'; 
import arrow from '../assets/arrow.png'; 

const styles = StyleSheet.create({
  background: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#254D4F',
  },
  logo: {
    width: '50%',
    height: '7%',
    marginTop: 70,
  },
  sub: {
    color: 'white',
    // fontFamily: 'Karrik',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 32,
    textAlign: 'left',
  },
  txt: {
    color: 'white',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 32,
    textAlign: 'left',
    marginTop: 26,
    // lineHeight: 55,
  },
  txtGold: {
    color: '#EBD4B3',
  },
  arrow: {
    width: '100%',
    height: 20,
  },
  center: {
    width: '73%',
  },
  btnTxt: {
    color: '#2F4D4F',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 24,
  },
  btn: {
    width: '50%',
    marginBottom: 40,
    padding: 10,
    alignItems: 'center',
    backgroundColor: '#E9D3B9',
    borderRadius: 25,
  }
});

export default function Splash(props) {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible while we fetch resources
        await SplashScreen.preventAutoHideAsync();
        // Pre-load fonts, make any API calls you need to do here
        await Font.loadAsync(Entypo.font);
        // Artificially delay for two seconds to simulate a slow loading
        // experience. Please remove this if you copy and paste the code!
        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <View
        style={styles.background}
        onLayout={onLayoutRootView}>
        <Image style={styles.logo} source={logo} resizeMode="contain" />
        <View style={styles.center}>
        <Text style={styles.sub}>hello,</Text>
        <Text style={styles.txt}>we are <Text style={styles.txtGold}>Tipitizi</Text>, we aim at helping those who deserve the most to be rewarded accordingly.</Text>
        </View>
        <TouchableOpacity style={styles.btn}onPress={() => {props.navigation.navigate('Home')}}>
          <Text style={styles.btnTxt}>start tipping</Text>
        </TouchableOpacity>
    </View>
  );

}
