import React, {useState, useEffect, useCallback} from "react";
import { View, Text } from "react-native";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { NavigationContainer } from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import Splash from './container/splash.js';
import Home from './container/home.js';
import Detail from './container/detail.js';
import Thanks from './container/thanks.js';
import Privacy from './container/privacy.js';
import First from './container/first.js';
import Second from './container/second.js';
import Third from './container/third.js';
import Signup from './container/signup.js';
import ThanksSignup from './container/thanksSignup.js';

const Stack = createStackNavigator();
const config = {
  screens: {
    Thanks: '/payment_validated',
    Detail: '/hotel',
    Home: '/home',
    ThanksSignup: '/account_created',
    Signup: {
      path: "signup",
      parse: {
        id: (id) => `hotel-${id}`,
      },
      stringify: {
        id: (id) => id.replace(/^hotel-/, '')
      }
    }
  },
};

const linking = {
  prefixes: ['https://mychat.com', 'mychat://'],
  config,
};

export default function App() {

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: false,
        }}>
        <Stack.Screen name="Splash" component={Splash} />
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="Detail" component={Detail} />
        <Stack.Screen name="First" component={First} />
        <Stack.Screen name="Second" component={Second} />
        <Stack.Screen name="Third" component={Third} />
        <Stack.Screen name="Thanks" component={Thanks} />
        <Stack.Screen name="Privacy" component={Privacy} />
        <Stack.Screen name="Signup" component={Signup} />
        <Stack.Screen name="ThanksSignup" component={ThanksSignup} />
      </Stack.Navigator>
    </NavigationContainer>
  );

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
